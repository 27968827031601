import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from './store';
import { AuthRouting } from './pages/AuthPages/Auth.routing';
import { PrivateRoute } from './common/utils/routing';
import Dashboard from './pages/Dashboard/Dashboard.container';
import OpportunitiesContainer from './pages/Opportunities/Opportunities.container';
import RequestsContainer from './pages/Requests/Requests.container';
import 'time-input-polyfill/auto';
import { FindAssistanceContainer } from './pages/FindAssistance/FindAssistance.container';
import { SettingsContainer } from './pages/Settings/Settings.container';
import UpdatesContainer from './pages/Updates/Updates.container';
import { ServiceHistoryContainer } from './pages/ServiceHistory/ServiceHistory.container';
import { ActivityContainer } from './pages/Activity/Activity.container';
import { HelpContainer } from './pages/ProfileHelp/Help.container';
import { NotificationsContainer } from './pages/Notifications/Notifications.container';
import { MainChatContainer } from './pages/Messages/Main/Main.chat.container';
import { NewLanding } from './pages/Landing/NewLanding';
import { Features } from './pages/Landing/Features';
import { NewTerms } from './pages/Landing/NewTerms';
import { NewPolicy } from './pages/Landing/NewPolicy';
import {
    UserProfileModalContainer,
    ReloginAlertModal,
    ConnectPaymentModal,
} from './common/components/modals';
import { logout, refreshToken } from './store/actions/auth/auth.actions';
import { RefreshTokenPayload } from './common/types';
import { AgencyRelationshipContainer } from './pages/AgencyRelationship/AgencyRelationship.container';
import { ContactContainer } from './pages/Landing/Contact/ContactContainer';
import { WhyZootProContainer } from './pages/Landing/WhyZootPro/WhyZootProContainer';
import { EdgeZootProContainer } from './pages/Landing/EdgeZootPro/EdgeZootProContainer';
import { AboutUsContainer } from './pages/Landing/AboutUs/AboutUsContainer';
import { FAQContainer } from './pages/Landing/FAQ/FAQContainer';
import {
    getUnreadNotifications,
    setConnectPaymentModalOpen,
    setNotificationsAvailableRequestModal,
    setNotificationsJobDetailsModal,
    setNotificationsMyRequestsModal,
    setNotificationsOpenhouseDetailsModal,
} from './pages/Notifications/store/actions';
import { HowItWorks } from './pages/Landing/HowItWorks';
import { Pricing } from './pages/Landing/Pricing';
import UnsubscribeContainer from './pages/Unsubscribe/Unsubscribe.container';
import { OauthPage } from './pages/OauthPage';
import { HostOpenHouseContainer } from './pages/HostOpenHouse/HostOpenHouse.container';
import { AvailableRequestDetailsModal, MyRequestInfoModal } from './pages/Requests/modals';
import { acceptRequest } from './pages/Requests/store/actions';
import { MyAgency } from './pages/MyAgency/MyAgency';
import { MyHome } from './pages/MyHome/MyHome';
import { MyStuff } from './pages/MyStuff/MyStuff';
import { HOMEPAGE_PATH } from './common/constants';
import RequestDemo from './pages/RequestDemo/RequestDemo';
import { JobDetailsModal } from './pages/Opportunities/modals';
import { OpenhouseDetailsModal } from './pages/Listings/modals';
import { getMyListings } from './store/actions/listings.actions';
import { UpdatesWraper } from './pages/Updates/UpdatesWraper';
import { MarketContainer } from './pages/Market/MarketContainer';
import { MarketContainerService } from './pages/Market/MarketContainerService';
import { NewLanding2 } from './pages/Landing/NewLanding2';
import { MarketListAllContainer } from './pages/Market/MarketListAllContainer';

const App: React.FC = () => {
    
    const dispatch = useDispatch();
    const onLogout = () => dispatch(logout());
    const onRefreshToken = (payload: RefreshTokenPayload) => dispatch(refreshToken(payload));

    const profile = useSelector((state: RootStore) => state.profile);
    const accessToken = useSelector((state: RootStore) => !!state.auth?.user?.token?.accessToken);
    const refreshTokenValue = useSelector(
        (state: RootStore) => state.auth?.user?.token?.refreshToken,
    );
    const tokenExpiration = useSelector(
        (state: RootStore) => state.auth?.user?.token?.accessTokenExpiration,
    );
    const accessTokenValue = useSelector(
        (state: RootStore) => state.auth?.user?.token?.accessToken,
    );
    const { value: accountStatus } = useSelector(
        (state: RootStore) => state.payments.getCustomerAccountStatus,
    );
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed =
        roles?.some((role: string) => (role === 'user' || role === 'newuser')) && accountStatus
            ? accountStatus === 'verified'
            : true;
    const {
        showAvailableRequestModal,
        showMyRequestModal,
        showOpenhouseInfoModal,
        myRequestToShowId,
        toApplicants,
        isJobDetailsModalOpen,
        jobDetailsId,
        openhouseDetailsId,
        isConnectPaymentModalOpen,
    } = useSelector((state: RootStore) => state.notifications.redirects);

    const { value: requestInfo, loading: requestInfoLoading } = useSelector(
        (state: RootStore) => state.requests.requestInfo,
    );

    // refresh token before 3 days to expiration or logout
    useEffect(() => {
        const threeDaysBeforeTokenExpired = new Date(tokenExpiration).getTime() - 86400 * 1000 * 3;

        if (new Date(tokenExpiration) < new Date()) {
            onLogout();
        } else if (
            new Date(threeDaysBeforeTokenExpired) < new Date() &&
            new Date(tokenExpiration) > new Date()
        ) {
            onRefreshToken({ accessToken: accessTokenValue, refreshToken: refreshTokenValue });
        }
    }, []);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (accessToken && roles?.some((role: string) => role === 'user')) {
            intervalId = setInterval(() => {
                dispatch(getUnreadNotifications());
            }, 30000);
        }
        return () => clearInterval(intervalId);
    }, [accessToken]);

    const handleAcceptRequest = (IsAccepted: boolean, RequestId: number) => {
        dispatch(
            acceptRequest({
                RequestId,
                IsAccepted,
                PageSize: 10,
                includeOtherAgent: false,
                categoryIds: [],
                date: '',
            }),
        );
    };

    const handleJobDetailsModalClose = () => {
        dispatch(setNotificationsJobDetailsModal({ id: 0, isOpen: false }));
    };

    const handleOpenhouseDetailsModalClose = () => {
        dispatch(setNotificationsOpenhouseDetailsModal({ id: 0, isOpen: false }));
    };

    return (
        <>
            <Switch>
                {accessToken ? (
                    <Redirect from="/" exact to={HOMEPAGE_PATH} />
                ) : (
                    <Route path={'/'} exact component={NewLanding2} />
                )}
                <Route path={'/newlanding'} exact component={NewLanding2} />
                <Route path={'/auth'} component={AuthRouting} />
                <PrivateRoute
                    path={'/opportunities'}
                    component={OpportunitiesContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/requests"
                    component={RequestsContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                {/* <PrivateRoute
                    path="/service-history"
                    component={ServiceHistoryContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                /> */}
                <PrivateRoute
                    path="/market"
                    component={MarketContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/market-demo/list-my/all/:service?"
                    component={MarketListAllContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/market-demo/list-all/:service?"
                    component={MarketListAllContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/market-demo/:service?"
                    component={MarketContainerService}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/activity"
                    component={ActivityContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/notifications"
                    component={NotificationsContainer}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <Route path="/pricing" component={Pricing} />
                <Route path="/privacy-policy" component={NewPolicy} />
                <Route path="/terms-and-conditions" component={NewTerms} />
                <Route path="/agency-relationship" component={AgencyRelationshipContainer} />
                <Route path="/contact" component={ContactContainer} />
                <Route path="/why-zoot-pro" component={WhyZootProContainer} />
                <Route path="/zoot-pro-edge" component={EdgeZootProContainer} />
                <Route path="/about-us" component={AboutUsContainer} />
                <Route path="/help" component={FAQContainer} />
                <Route path="/request-demo" component={RequestDemo} />
                <PrivateRoute
                    path="/home"
                    component={MyHome}
                    isSignedIn={accessToken}
                    isAccessAllowed={true}
                />
                <PrivateRoute
                    path="/my-agency/:tab?"
                    component={MyAgency}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/my-stuff/:tab?"
                    component={MyStuff}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />

                <PrivateRoute
                    path={'/app'}
                    isSignedIn={accessToken}
                    component={Dashboard}
                    isAccessAllowed={true}
                />
                <PrivateRoute
                    path="/messages/:id"
                    isSignedIn={accessToken}
                    component={MainChatContainer}
                    render={({ match }) => <MainChatContainer match={match} />}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/settings"
                    isSignedIn={accessToken}
                    component={SettingsContainer}
                    isAccessAllowed={true}
                />
                <PrivateRoute
                    path="/help"
                    isSignedIn={accessToken}
                    component={HelpContainer}
                    isAccessAllowed={true}
                />
                <PrivateRoute
                    path={'/opportunities'}
                    isSignedIn={accessToken}
                    component={OpportunitiesContainer}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/find-assistance/:preferred?"
                    isSignedIn={accessToken}
                    component={FindAssistanceContainer}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path={'/updates'}
                    component={UpdatesWraper}
                    isSignedIn={accessToken}
                    isAccessAllowed={isAccessAllowed}
                />
                <PrivateRoute
                    path="/host-open-house"
                    isSignedIn={accessToken}
                    component={HostOpenHouseContainer}
                    isAccessAllowed={isAccessAllowed}
                />
                <Route path="/features" component={Features} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/how-it-works" component={HowItWorks} />
                <Route path="/oauth-page" component={OauthPage} />
                <Route path={'/unsubscribe/:email/:hash'} exact component={UnsubscribeContainer} />
                <Redirect from="/InviteUser" to="/auth/signup/createaccount" />
                <Redirect from="*" exact to={accessToken ? HOMEPAGE_PATH : '/auth'} />
            </Switch>
            {Object.keys(profile).map(elem => (
                <UserProfileModalContainer key={elem} userId={+elem} />
            ))}
            <ReloginAlertModal />
            <AvailableRequestDetailsModal
                isOpen={showAvailableRequestModal}
                onClose={() => {
                    dispatch(setNotificationsAvailableRequestModal({ isOpen: false }));
                }}
                requestInfo={requestInfo}
                requestInfoLoading={requestInfoLoading}
                handleAcceptRequest={handleAcceptRequest}
            />
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={{ isOpen: showOpenhouseInfoModal as boolean }}
                setShowAddTimeSlotModal={() => {}}
                onClose={handleOpenhouseDetailsModalClose}
                listingId={openhouseDetailsId}
                getItems={() => dispatch(getMyListings())}
            />
            <MyRequestInfoModal
                isOpen={showMyRequestModal}
                onClose={() => {
                    dispatch(
                        setNotificationsMyRequestsModal({
                            id: 0,
                            isOpen: false,
                            toApplicants: false,
                        }),
                    );
                }}
                pendingRequestToShowId={myRequestToShowId}
                toApplicants={toApplicants}
            />
            <JobDetailsModal
                isOpen={isJobDetailsModalOpen}
                onClose={handleJobDetailsModalClose}
                jobId={jobDetailsId}
            />
            <ConnectPaymentModal
                isOpen={isConnectPaymentModalOpen}
                onClose={() => {
                    dispatch(setConnectPaymentModalOpen({ isOpen: false }));
                }}
            />
        </>
    );
};

export default App;
