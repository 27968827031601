import React, { useEffect, useState } from 'react';
import { Button, Flex, Select, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomDropdown, CustomInput } from '../../../../../common/components';
import { addLicenseResolver } from '../../../../../common/schemes';
import {
    AddLicensePayload,
    LicenseDraftForm,
    LicenseDraftType,
    LicenseType,
    SaveLicenseDraftPayload,
} from '../../../types';
import { StateDirectoryItem, GetCitiesResponse, SelectValue, IdNameTypes } from '../../../../../common/types';
import { SearchCitiesSelect } from '../../../../../common/components/blocks/SearchCitiesSelect';
import { LicenseExpiryLable } from './LicenseExpiryLable';
import { useLicense } from '../hooks/useLicense';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { renewLicenseRequest } from '../../../store/actions';
import { getAgenciesTypesRequest, getAllAgenciesTypesRequest } from '../../../../../store/actions/directories.actions';
import { SingleValue } from 'react-select';
import axios from 'axios';
import { Alerter } from '../../../../../common/utils/Alerter';

type ButtonEvent = React.MouseEvent<HTMLButtonElement>;

interface ILicense {
    license: LicenseType;
    states: StateDirectoryItem[];
    draft: LicenseDraftType;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
    index: number;
    showLicenseEditor: boolean;
    accessToken: string;
    choosenItem: number | null;
    setStateId: (v: string) => void;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    addLicense: (v: AddLicensePayload) => void;
    handleAddLicense: () => void;
    setChoosenItem: (id: number | null) => void;
}

export const LicenseItem: React.FC<ILicense> = ({
    license,
    states,
    draft,
    saveDraftLoading,
    index,
    accessToken,
    choosenItem,
    setStateId,
    saveLicenseDraft,
    setChoosenItem,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
    } = useForm<LicenseDraftForm>({
        resolver: addLicenseResolver,
        defaultValues: { agency: license.agencyId, agencyId: license.agencyId },
    });

    const toast = useToast();
    const { expirationStatus, expiresIn } = useLicense(license);

    const { loading: isLicenseRenewing } = useSelector(
        (state: RootStore) => state.settings.renewLicense,
    );

    const settingsFF = useSelector(
        (state: RootStore) => state.settings,
    );

    //console.log('11111', settingsFF);

    const dispatch = useDispatch();

    const { agenciesAll } = useSelector(
        (state: RootStore) => state.directories,
    );

    useEffect(() => {
        //console.log('URRRRRRRAAAAAA', agenciesAll);
    }, [agenciesAll]);


    const [typeIdsSelected, setTypeIdsSelected] = useState<GetCitiesResponse[]>(
        license.areasOfServiceModels,
    );
    const [stateIdValue, setStateIdValue] = useState(0);
    const [editMode, seteditMode] = useState<boolean>(false);

    const [modalType, setModalType] = useState('existing');

    useEffect(() => {
        if (choosenItem === index) {
            setModalType('new');
        } else {
            setModalType('existing');
        }
    }, [index, choosenItem]);

    const onSubmit = async (data: LicenseDraftForm) => {
        const editPayload: SaveLicenseDraftPayload = {
            state: data.state,
            licenseNumber: data.licenseNumber,
            mlsId: data.mlsId,
            preferredAreasOfServices: typeIdsSelected,
            //agencyId: Number(data.agency) || license.agencyId,
            agencyId: data.agencyId,
            changeLicensePreferredAreasOfServiceModel: [
                {
                    licenseId: license.id,
                    preferredAreasOfServiceModels: typeIdsSelected,
                },
            ],
        };

        await axios.put(window.baseUrl+'/api/profile/save-license-draft', editPayload)
            .then(async response => {
                Alerter.success('Saved successfully');
                saveLicenseDraft(editPayload);
                setChoosenItem(null);
                seteditMode(false);
                location.reload();
            }).catch(error => {
                let status = error.response.status;
                if(status === 422){
                    toast({
                        title: error.response.data.errors[0].errorMessage,
                        status: 'error',
                        isClosable: true,
                    });
                }
                
                resetFields();
            });

        /*
        const editPayload: SaveLicenseDraftPayload = {
            state: data.state,
            licenseNumber: data.licenseNumber,
            mlsId: data.mlsId,
            preferredAreasOfServices: typeIdsSelected,
            //agencyId: Number(data.agency) || license.agencyId,
            agencyId: data.agencyId,
            changeLicensePreferredAreasOfServiceModel: [
                {
                    licenseId: license.id,
                    preferredAreasOfServiceModels: typeIdsSelected,
                },
            ],
        };
        saveLicenseDraft(editPayload);
        setChoosenItem(null);
        seteditMode(false);
        location.reload();
        */
    };

    useEffect(() => {
        setValue('agencyId', license.agencyId);
        setValue('state', license.state);
        setValue('licenseNumber', license.license);
        setValue('mlsId', license.mlsId);
    }, []);

    useEffect(() => {
        setStateIdValue(findStateId(license.state));
        setTypeIdsSelected(license.areasOfServiceModels);
    }, []);

    useEffect(() => {
        setValue('agencyId', license.agencyId);
    }, [agenciesAll]);

    const resetFields = () => {
        reset({
            mlsId: license.mlsId,
            licenseNumber: license.license,
            state: license.state,
            agencyId: license.agencyId,
        });

        setChoosenItem(null);
    };

    const findStateId = (state: string): number => {
        const id = states.find(st => st.name === state)?.id || 1;
        return id;
    };

    const displayRenewButton = expirationStatus === 'expired' || expirationStatus === 'expiring';

    const renewLicense = async () => {
        dispatch(renewLicenseRequest({ id: license.id }));
    };

    var stateId = getValues('state');
    var calcStateId = findStateId(stateId);

    const agenciesAllFiltered = agenciesAll
        .filter((el: any) => {
            if(!calcStateId){
                return false;
            }
            return el.states.includes(calcStateId);
        })
        .map((el: IdNameTypes) => {
            return {
                id: el.id,
                label: el.name,
                value: el.id,
            };
        });
        
    return (
        //@ts-ignore
        <Flex flexDirection="column" borderTop={index && '1px solid #D1D2DB'} className="lecense_item">
            <Flex mt="20px" alignItems="center">
                
                <Text fontSize="18px" mr="8px" fontWeight="600" data-id-license={license.id}>
                    {license.state}
                </Text>

                <LicenseExpiryLable status={expirationStatus} expiresIn={expiresIn} />

                {(!license.isVerified || license.isNew) && (
                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        textDecoration={'underline'}
                        color="#F2C94C"
                        ml="auto"
                    >
                        Verification Processing
                    </Text>
                )}
            </Flex>

            <Flex direction="column" align="center">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid columns={2} gap="100px" rowGap="0px" mt="10px" mb="40px">
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                disabled
                                label="State"
                                errors={errors.state}
                                width="450px"
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                                placeholder={license.state}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">

                            <div className="agency_flex">
                                <CustomDropdown
                                    disabled={!editMode}
                                    label="Agency *"
                                    placeholder="Select"
                                    options={agenciesAllFiltered}
                                    register={{ ...register('agencyId') }}
                                    width="100%"
                                />
                            </div>
                            
                            {/*
                            <CustomInput
                                disabled
                                label="Agency"
                                errors={errors.agency}
                                width="100%"
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                                placeholder={license.agencyName}
                            />
                            */}

                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="RE License"
                                placeholder="RE License"
                                register={{ ...register('licenseNumber') }}
                                width="100%"
                                defaultValue={
                                    modalType === 'existing'
                                        ? license.license
                                        : modalType === 'draft'
                                        ? draft.licenseNumber
                                        : ''
                                }
                                errors={errors.licenseNumber}
                                disabled={!editMode}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>
                        <Flex w="100%" flexDir="column">
                            <Text
                                fontWeight="300"
                                fontSize="16px"
                                textSize="16px"
                                fontFamily="poppins"
                            >
                                Areas of Service
                            </Text>
                            <SearchCitiesSelect
                                stateIdValue={stateIdValue}
                                setTypeIdsSelected={setTypeIdsSelected}
                                typeIdsSelected={typeIdsSelected}
                                // disabled={modalType === 'existing'}
                                token={accessToken}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="MLS ID"
                                placeholder="MLS ID"
                                register={{ ...register('mlsId') }}
                                width="100%"
                                defaultValue={
                                    modalType === 'existing'
                                        ? license.mlsId
                                        : modalType === 'draft'
                                        ? draft.mlsId
                                        : ''
                                }
                                errors={errors.mlsId}
                                disabled={!editMode}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>

                        <Flex w="100%" justify="flex-end" alignItems="center" mt="20px">
                            {modalType === 'new' && (
                                <CustomButton
                                    text="Cancel"
                                    isLoading={saveDraftLoading}
                                    width="100px"
                                    height="50px"
                                    bgColor="#fff"
                                    borderColor="brand"
                                    color="brand"
                                    margin="0 0 20px 0"
                                    onClick={resetFields}
                                />
                            )}
                            {displayRenewButton && (
                                <Button
                                    variant="link"
                                    color="azure"
                                    disabled={!editMode}
                                    onClick={renewLicense}
                                >
                                    Click Here If Your License Is Renewed
                                </Button>
                            )}
                            {editMode? (
                                <CustomButton
                                    text="Save"
                                    type="submit"
                                    w="110px"
                                    height="50px"
                                    bgColor="brand"
                                    ml={6}
                                    isLoading={saveDraftLoading}
                                />
                            ) : (
                                <CustomButton
                                    text="Edit"
                                    w="110px"
                                    height="50px"
                                    bgColor="brand"
                                    ml={6}
                                    onClick={e => {
                                        e.preventDefault();
                                        seteditMode(true);
                                    }}
                                />
                            )}
                        </Flex>
                    </SimpleGrid>
                </form>
            </Flex>
        </Flex>
    );
};
