import React, { useEffect } from 'react';
import { Box, Divider, Flex, Stack } from '@chakra-ui/react';

import { SidebarDropdown } from './SidebarDropdown/SidebarDropdown';
import { SidebarButton } from '../../common/components/blocks/SidebarButton/SidebarButton';
import { SidebarAgencyButton } from '../../common/components/blocks/SidebarAgencyButton/SidebarAgencyButton';

import { State } from './types';
import {
    GetHelpNow,
    Activity,
    RequestsIcon,
    NewOpportunity,
    NewBlast,
} from '../../assets/icons/SidebarIcons';
import { Home } from '../../assets/icons/Home';
import { Home2, Home2v2 } from '../../assets/icons/Home2';
import { BlastIcon, BlastIconNew, BlastIconNewV2, BuildSmall, OffMarketV2, SettingsIconV2 } from '../../assets/icons/Blast';
import { RequestIcon, RequestIconV2 } from '../../assets/icons/RequestIcon';
import { ListingIcon, ListingIconV2 } from '../../assets/icons/ListingIcon';
import { AgentIcon, AgentIconV2 } from '../../assets/icons/AgentIcon';
import { CalendarIcon2, CalendarIcon2V2 } from '../../assets/icons/CalendarIcon2';
import { MyListing, MyListingV2 } from '../../assets/icons/MyListing';
import { ClientsIcon, ClientsIconV2 } from '../../assets/icons/ClientsIcon';
import { BtnAddRequest } from '../../common/components/aglobal/BtnAddRequest';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store';

type ModalOptionsProps = {
    type: '' | 'getHelpNow' | 'newPostModal' | 'newBlast';
};

interface ISidebar {
    isRequestModalOpen: boolean;
    setIsRequestModalOpen: (arg0: boolean) => void;
    history: any;
    states: State[];
    isAccessAllowed: boolean;
    setOpenModal: (type: ModalOptionsProps) => void;
}

const Sidebar: React.FC<ISidebar> = ({ states, isAccessAllowed, setOpenModal }) => {
    const openModal = (type: ModalOptionsProps['type']) => setOpenModal({ type });

    const {requests, activity} = useSelector((state: RootStore) => state.user.unreads);

    useEffect(() => {
        
    }, [requests]);

    return (
        <>
            <Box pl="20px" pr="30px" pt="25px" className="sidebar_box_main">
                <Flex d="flex" alignItems="flex-start" flexDirection="column">
                    {/*
                    <Stack
                        spacing={3}
                        d="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        >
                        <SidebarDropdown options={states} />
                    </Stack>
                    */}
                    {/*
                    <Divider border="1px solid #CDDFEF" mt="35px" mb="35px" />
                    */}
                    <Stack
                        spacing={3}
                        d="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        >
                        <BtnAddRequest
                            type="big"
                        />

                        <SidebarButton
                            bgColor={(location.pathname == '/home')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/home')? 'active_btn' : ''}
                            text="Home"
                            asLink
                            href="/home"
                            icon={<Home2v2 isActive={(location.pathname == '/home')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            bgColor={(location.pathname == '/updates')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/updates')? 'active_btn' : ''}
                            text="Blasts"
                            asLink
                            href="/updates"
                            icon={<BlastIconNewV2 isActive={(location.pathname == '/updates')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            unreads={requests}
                            bgColor={(location.pathname == '/requests')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/requests')? 'active_btn' : ''}
                            text="Coverage"
                            asLink
                            href="/requests"
                            icon={<RequestIconV2 isActive={(location.pathname == '/requests')? true : false} />}
                            isAccessAllowed={true}
                        />
                        {/*
                        <SidebarButton
                            bgColor={(location.pathname == '/market-demo')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/market-demo')? 'active_btn' : ''}
                            text="Off-Market"
                            asLink
                            href="/market-demo"
                            icon={<OffMarketV2 />}
                            isAccessAllowed={true}
                        />
                        */}
                        <SidebarButton
                            bgColor={(location.pathname == '/market')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/market')? 'active_btn' : ''}
                            text="Off-Market"
                            asLink
                            href="/market"
                            icon={<OffMarketV2 />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            unreads={activity}
                            bgColor={(location.pathname == '/activity')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/activity')? 'active_btn' : ''}
                            text="Activity"
                            asLink
                            href="/activity"
                            icon={<CalendarIcon2 />}
                            isAccessAllowed={true}
                        />
                        <div className="menu_title">
                            BROKERAGE
                        </div>
                        <SidebarButton
                            bgColor={(location.pathname == '/my-agency/0')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/my-agency/0')? 'active_btn' : ''}
                            text="Listings"
                            asLink
                            href="/my-agency/0"
                            icon={<ListingIconV2 isActive={(location.pathname == '/my-agency/0')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            bgColor={(location.pathname == '/my-agency/1')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/my-agency/1')? 'active_btn' : ''}
                            text="Agents"
                            asLink
                            href="/my-agency/1"
                            icon={<AgentIconV2 isActive={(location.pathname == '/my-agency/1')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            bgColor={(location.pathname == '/my-agency/2')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/my-agency/2')? 'active_btn' : ''}
                            text="Calendar"
                            asLink
                            href="/my-agency/2"
                            icon={<CalendarIcon2V2 isActive={(location.pathname == '/my-agency/2')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <div className="menu_title">
                            MY STUFF
                        </div>
                        <SidebarButton
                            bgColor={(location.pathname == '/my-stuff/0')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/my-stuff/0')? 'active_btn' : ''}
                            text="My Listings"
                            asLink
                            href="/my-stuff/0"
                            icon={<MyListingV2 isActive={(location.pathname == '/my-stuff/0')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            bgColor={(location.pathname == '/my-stuff/1')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/my-stuff/1')? 'active_btn' : ''}
                            text="Clients"
                            asLink
                            href="/my-stuff/1"
                            icon={<ClientsIconV2 isActive={(location.pathname == '/my-stuff/1')? true : false} />}
                            isAccessAllowed={true}
                        />
                        <SidebarButton
                            bgColor={(location.pathname == '/market')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/market')? 'active_btn' : ''}
                            text="Off-Market"
                            asLink
                            href="/market"
                            icon={<OffMarketV2 />}
                            isAccessAllowed={true}
                        />

                        <br />
                        <SidebarButton
                            bgColor={(location.pathname == '/settings')? '#FFFFFF' : 'transparent'}
                            classN={(location.pathname == '/settings')? 'active_btn' : ''}
                            text="Settings"
                            asLink
                            href="/settings"
                            icon={<SettingsIconV2 />}
                            isAccessAllowed={true}
                        />

                        

                        {/*
                        <SidebarButton
                            text="Get Help Now!"
                            icon={<GetHelpNow />}
                            onClick={() => openModal('getHelpNow')}
                            isAccessAllowed={isAccessAllowed}
                        />
                        <SidebarButton
                            text="Post Agent Opportunity"
                            icon={<NewOpportunity />}
                            isAccessAllowed={isAccessAllowed}
                            onClick={() => openModal('newPostModal')}
                        />
                        */}

                        {/*
                        <SidebarButton
                            text="Post Agent Blast"
                            icon={<NewBlast />}
                            onClick={() => openModal('newBlast')}
                            isAccessAllowed={isAccessAllowed}
                        />
                        <SidebarButton
                            text="+New Off-Market-Need"
                            icon={<NewOffMarketNeed />}
                            onClick={() => null}
                            isAccessAllowed={isAccessAllowed}
                        /> */}
                    </Stack>
                    
                    <br />
                    <br />
                    {/*
                    <Divider borderBottom="1px solid #CDDFEF" mt="35px" mb="35px" />
                    <SidebarAgencyButton
                        text="Coverage"
                        href="/app"
                        isAccessAllowed={isAccessAllowed}
                    />
                    */}
                </Flex>
            </Box>
            {/* {isRequestModalOpen ? (
                <Requests
                    isOpen={isRequestModalOpen}
                    onClose={() => setIsRequestModalOpen(false)}
                />
            ) : null} */}
        </>
    );
};

export default Sidebar;
