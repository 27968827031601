import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DasboardContent } from './DasboardContent';
import { DasboardContentListAll } from './DasboardContentListAll';

export const MarketListAllContainer: React.FC = () => {

    return (
        <>
            <DashboardWrapper
            backgroundCOlorCont = "#EBF2F8"
            paddingBottom="0px"
            paddingTop="0px"
            paddingLeft="0px"
            paddingRight="0px"
            sidebar={<SidebarContainer isAccessAllowed />}
            content={<DasboardContentListAll />}
            />
        </>
    );
};
