import React from 'react';
import { Box, Container, ContainerProps, Flex } from '@chakra-ui/react';
import { DashboardHeader } from './DashboardHeader';

interface IDashboard {
    sidebar: React.ReactNode;
    content?: React.ReactNode;
    maxW?: ContainerProps['maxW'];
    paddingLeft?: string,
    paddingRight?: string,
    paddingTop?: string,
    paddingBottom?: string,
    backgroundCOlorCont?: string,
}

export const DashboardWrapper: React.FC<IDashboard> = ({
    sidebar,
    content,
    maxW = 'container.xl',
    paddingLeft = '30px',
    paddingRight = '30px',
    paddingTop = '0px',
    paddingBottom = '0px',
    backgroundCOlorCont = '#FFFFFF',
}) => {
    return (
        <Flex backgroundColor="brand" flexDirection="column" alignItems="center" minH="100vh">
            <DashboardHeader />
            <Container maxW={maxW}>
                <Flex mt="10px" borderRadius="8px" backgroundColor="#EBF2F8FF">
                    <Box>{sidebar}</Box>
                    <Box
                        className="box_right_container"
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingLeft={paddingLeft}
                        paddingRight={paddingRight}
                        bgColor={backgroundCOlorCont}
                        borderRadius="10px"
                        position="relative"
                        flex="1"
                    >
                        {content}
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
};
