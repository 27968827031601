import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import SidebarContainer from '../Sidebar/Sidebar.container';

export const DasboardContent: React.FC = () => {

    useEffect(() => {
        const script = document.createElement('script');
    
        script.src = "https://market.1csmart.by/app.site.js";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <>  
            <div style={{fontSize:'26px'}}>
                Coming soon
            </div>
            {/*
                <>
                1111111111
                <div id="appmarket"></div>
                <>
            */}
        </>
    );
};
