import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from '../../common/components/blocks/DashboardWrapper/DashboardWrapper';
import SidebarContainer from '../Sidebar/Sidebar.container';
import { DasboardContent } from './DasboardContent';

export const MarketContainer: React.FC = () => {

    return (
        <DashboardWrapper
        paddingBottom="20px"
        paddingTop="20px"
        paddingLeft="20px"
        paddingRight="20px"
        sidebar={<SidebarContainer isAccessAllowed />}
        content={<DasboardContent />}
        />
    );
};
