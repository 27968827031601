import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, Center, Button, Link, Image, FormControl, FormLabel, Switch, useToast, Spinner } from '@chakra-ui/react';
import { GooglePlayIcon, AppleIcon, ListCheckmark, CheckIcon } from '../../../../assets/icons';
import SubscriptionPentagon from '../../../../assets/img/subscribe_pentagon.png';
import { fonts } from '../../../../common/utils';
import { useSelector } from 'react-redux';
import { dispatch, RootStore } from '../../../../store';
import { AddBalanceModal } from '../../../../common/components/aglobal/AddBalanceModal';
import { AddSubscribeModal } from '../../../../common/components/aglobal/AddSubscribeModal';
import { getUserInfoRequest } from '../../../../store/actions/user.actions';
import { CalendarIcon2, CheckIconV2 } from '../../../../assets/icons/CalendarIcon2';
import { ImageSubscribe } from '../../../../assets/icons/OtherIcons';
import { CancelSubscribeModal } from '../../../../common/components/aglobal/CancelSubscribeModal';
import dayjs from 'dayjs';
import axios from 'axios';
import _ from 'lodash';

export const points = [
    'Showing Assistance',
    'Home Inspection',
    'Listing Management',
    'Open House',
    'Brokerage Networking',
];

interface IPriceBoxProps {
    color: string;
    amount: string;
    duration: string;
}

export interface Iplan {
  price: number;
  name: string;
  name2?: string;
}

const PriceBox: React.FC<IPriceBoxProps> = ({ amount, color, duration }) => (
    <Center bg={color}  borderRadius="16px" w="100%" h="120px" className="box_center">
        <Box>
            <Text
                fontSize="39px"
                color="white"
                textAlign="center"
                lineHeight="46px"
                fontWeight="600"
                fontFamily={fonts.avenirRoman}
            >
                ${amount}
            </Text>
            <Text
                fontSize="15px"
                color="white"
                lineHeight="20px"
                fontWeight={500}
                textAlign="center"
                fontFamily={fonts.avenirRoman}
            >
                {duration}
            </Text>
            <Text
              fontSize="20px"
              color="white"
              lineHeight="20px"
              fontWeight={700}
              textAlign="center"
              fontFamily={fonts.avenirRoman}
              >
              Pay
            </Text>
        </Box>
    </Center>
);

interface IPlan {
  price: number;
  name: string;
  name2: string;
}

export const AvailableOptionsOverview: React.FC = () => {
  const { user: currentUser } = useSelector((state: RootStore) => state.user);
  const [subscribeModal, setsubscribeModal] = useState(false);
  const [selectedPlan, setselectedPlan] = useState<Iplan>({name: '', price: 0, name2: ''});

  const toggleModal = () => {
    setsubscribeModal(!subscribeModal);
  };

  const toggleModalCancel = () => {
    setOpenCancelPackageModal(!openCancelPackageModal);
  };

  const openPackageModal = (plan: Iplan) => {
    setselectedPlan(plan);
    setsubscribeModal(true);
  };

  const closeModal = () => {
    setsubscribeModal(false);
  };

  const afterUpdatePlan = () => {

  };

  const [typePlan, setTypePlan] = useState(false);
  const [openCancelPackageModal, setOpenCancelPackageModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const toast = useToast();

  useEffect(() => {

  }, [typePlan]);

  useEffect(() => {
    if(!currentUser.id){
      return;
    }
    if(currentUser.subscribePlan === 'year'){
      setTypePlan(true);
    }else{
      setTypePlan(false);
    }
  }, [currentUser]);

  /*
  const cancelSubscribePlan = async () => {
    if(loader){
      return;
    }
    setLoader(true);

    await axios.post(window.baseUrl+'/api/stripe/ext/cancelSubscribePlan')
    .then(res => {
      //setLoader(false);
      location.reload();
    }).catch((error: any) => {
      setLoader(false);
      let status = error.response.status;
      
      if(status === 422){
        toast({
          title: error.response.data.error,
          status: 'error',
          isClosable: true,
        });
        return;
      }
    });
  }
  */

  const getCheckoutPage = async (data: IPlan) => {
    if(loader){
      return;
    }
    setLoader(true);

    await axios.post(window.baseUrl+'/api/stripe/getPageSession', {
      type: data.name,
    })
    .then(res => {
      setLoader(false);
      location.href = res.data.url;

    }).catch((error: any) => {
      setLoader(false);
      let status = error.response.status;
      
      if(status === 422){
        toast({
          title: error.response.data.error,
          status: 'error',
          isClosable: true,
        });
        return;
      }
    });
  }

  return (
    <>
        <div className="subscription_page_contaner">
          <div className="subscription_page">
            <div className="left_column">
              <div className="info_row"><CheckIconV2 className="icon_check" /> Showings Coverage</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Open House Coverage</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Appraisal & Home Inspection Coverage</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Access to Runners</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Send, Track, & Receive Referrals Nationwide</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Instant Access To Network Of Affiliated Agents</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Buyers Needs Matching</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Seller’s Needs Matching</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Instant Payment Transfers</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Intra-Brokerage Communication System</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Inter-Market Communication System</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Add Multiple Licenses</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Access Brokerage Exclusives</div>
              <div className="info_row"><CheckIconV2 className="icon_check" /> Agent-to-Agent Payments</div>
            </div>
            <div className="right_column">
              <div className="title_col">
                Premium Plan is 
                {currentUser.isActiveSubscribe? (
                  <span className="status">ACTIVE</span>
                ) : (
                  <span className="status notactive">NOT ACTIVE</span>
                )}

              </div>
              {currentUser.dateSubscribeFormat && currentUser.isActiveSubscribe && (
                <>
                  {currentUser.is_subscribe_free? 
                  (
                  <div className="desc_1 free">
                    Your free trial will expire at &nbsp;
                    { dayjs(currentUser.dateSubscribeFormat).format('MMM DD, YYYY') }
                  </div>
                  ) 
                  :
                  (
                    <div className="desc_1">
                      Next payment Date is &nbsp;
                      { dayjs(currentUser.dateSubscribeFormat).format('MMM DD, YYYY') }
                    </div>
                  )}
                </>
              )}
              {!currentUser.isActiveSubscribe && (
                <div className="desc_1 expired">
                  You dont have an active subscription
                </div>
              )}
              <div className="image_block">
                <ImageSubscribe />
              </div>

              <FormControl display='flex' alignItems='center' className="switcher_c">
                <div className="left_label">
                  Monthly
                </div>
                <Switch 
                  isChecked={typePlan} 
                  id='email-alerts' 
                  onChange={(val) => {
                    setTypePlan(val.target.checked);
                  }}
                />
                <div className="right_label">
                  Annually 
                  <span className="rl_bold">
                    30% OFF
                  </span>
                </div>
              </FormControl>

              <div className="title_2">
                Premium Plan
              </div>

              <div className="priceBlock">
                {typePlan? (
                  <div>$49.99 <span className="postfix">/per year</span></div>
                ) : (
                  <div>$5.99 <span className="postfix">/per month</span></div>
                )}
              </div>

              {(currentUser.isActiveSubscribe && !currentUser.is_subscribe_free)? 
                (
                  <>
                    {currentUser.isSubscribeCanceled?
                      (
                        <div className="btn_unsubscribe"
                        onClick={() => {

                        }}
                        >
                        Plan will be canceled at &nbsp;
                        { dayjs(currentUser.dateSubscribeFormat).format('MMM DD, YYYY') }
                      </div>
                      )
                      :
                      (
                      <div className="btn_unsubscribe"
                        onClick={() => {
                          //cancelSubscribePlan();
                          setOpenCancelPackageModal(true);
                        }}
                        >
                        {loader? (<Spinner />) : (
                          <span>Cancel Plan</span>
                        )}
                      </div>
                      )
                    }
                  </>
                )
                :
                (
                  <>
                    <div 
                      className="btn_subscribe"
                      onClick={() => {
                        if(typePlan){
                          getCheckoutPage({price: 49.99, name: '/year', name2: 'per year'});
                          //openPackageModal({price: 49.99, name: '/year', name2: 'per year'});
                        }else{
                          //openPackageModal({price: 5.99, name: '/month', name2: 'per month'});
                          getCheckoutPage({price: 5.99, name: '/month', name2: 'per month'});
                        }
                      }}
                      >
                      {loader? (<Spinner />) : (
                        <span>Subscribe to Premium Plan</span>
                      )}
                    </div>
                    {/*
                    <div className="btn_subscribe"
                      onClick={() => {
                        if(typePlan){
                          openPackageModal({price: 49.99, name: '/year', name2: 'per year'});
                        }else{
                          openPackageModal({price: 5.99, name: '/month', name2: 'per month'});
                        }
                      }}
                      >
                      Subscribe to Premium Plan
                    </div>
                    */}
                  </>
                )}
            </div>
          </div>
        </div>
        <CancelSubscribeModal
          isOpen={openCancelPackageModal}
          onClose={toggleModalCancel}
          emitSubmited={afterUpdatePlan}
        />
        <AddSubscribeModal
          selectedPlan={selectedPlan}
          isOpen={subscribeModal}
          onClose={toggleModal}
          emitSubmited={afterUpdatePlan}
        />
        {/*
        <Text
            color="brand"
            fontSize="18px"
            fontWeight={600}
            lineHeight="27px"
            textTransform="capitalize"
        >
            You are not subscribed. Download the mobile app In order to create a subscription for
            your account.
        </Text>
        <HStack mt="15px">
            <Button
                leftIcon={<GooglePlayIcon />}
                bg="rgba(44, 51, 92, 0.1)"
                borderRadius="25px"
                height="44px"
                color="brand"
                fontSize="12px"
                fontWeight={400}
                letterSpacing="0.1em"
                w="160px"
                as={Link}
                target={'_blank'}
                href="https://play.google.com/store/apps/details?id=com.zootpro"
            >
                Google Play
            </Button>
            <Button
                leftIcon={<AppleIcon />}
                bg="rgba(44, 51, 92, 0.1)"
                borderRadius="25px"
                height="44px"
                color="brand"
                fontSize="12px"
                fontWeight={400}
                letterSpacing="0.1em"
                w="160px"
                as={Link}
                target={'_blank'}
                href="https://apps.apple.com/app/zootpro/id1601250757"
            >
                App Store
            </Button>
        </HStack>
        {currentUser.isActiveSubscribe? (
          <Text
            color="brand"
            fontSize="16px"
            fontWeight={400}
            lineHeight="22px"
            mt="40px"
            mb="10px"
            >
            Your subscription is active until { currentUser.dateSubscribeFormat }
          </Text>
        ) : (
          <>
          <Text
            color="red"
            fontSize="16px"
            fontWeight={400}
            lineHeight="22px"
            mt="40px"
            mb="10px"
            >
            Your subscription has expired { currentUser.dateSubscribeFormat }
          </Text>
          <Text
            color="red"
            fontSize="16px"
            fontWeight={400}
            lineHeight="22px"
            mt="0px"
            mb="10px"
            >
            If you want to use this app, please subscribe!
          </Text>
        </>
        )}
        <Text
            color="brand"
            fontSize="18px"
            fontWeight={600}
            lineHeight="27px"
            mt="40px"
            mb="10px"
            textTransform="capitalize"
        >
            Available options for Subscription
        </Text>
        <Box
            bg="#4FD1C5"
            borderRadius="26px"
            p="30px"
            position="relative"
            overflow="hidden"
            mb="30px"
        >
            <Text
                fontSize="26px"
                lineHeight="26px"
                fontWeight={800}
                color="white"
                mb="20px"
                fontFamily={fonts.avenirRoman}
            >
                Subscription Include:
            </Text>
            {points.map(elem => (
                <HStack key={elem} mb="10px">
                    <Center w="20px" h="20px" borderRadius="10px" bg="rgba(255,255,255, .1)">
                        <ListCheckmark />
                    </Center>
                    <Text
                        color="white"
                        fontSize="15px"
                        lineHeight="20px"
                        fontWeight={500}
                        fontFamily={fonts.avenirRoman}
                    >
                        {elem}
                    </Text>
                </HStack>
            ))}
            <Image src={SubscriptionPentagon} position="absolute" top="0" left="200px" />
        </Box>
        <HStack spacing="30px">
          <div className="flex_full" onClick={() => {openPackageModal({price: 9.99, name: '/month'})}}>
            <PriceBox amount="9.99" color="#867AFA" duration="/month" />
          </div>
          <div className="flex_full" onClick={() => {openPackageModal({price: 9.99, name: '/month'})}}>
            <PriceBox amount="69.99" color="#f2c94c" duration="/year" />
          </div>
        </HStack>
        <br/>
        <br/>
        <br/>
        <AddSubscribeModal
          selectedPlan={selectedPlan}
          isOpen={subscribeModal}
          onClose={toggleModal}
          emitSubmited={afterUpdatePlan}
      />
      */}
    </>
)};
