import React from 'react';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';

import { SignUpForm } from './components/SignUpForm/SignUpForm';

import { ISignUpProps } from './types';

import { BackArrow } from '../../../assets/icons';
import { AuthCardPanel } from '../../../common/components/aglobal/AuthCardPanel';
import { GoogleAuth } from '../SignIn/GoogleAuth';

export const SignUp: React.FC<ISignUpProps> = ({
    loading,
    stateErrors,
    nestedPath,
    onSendEmailCode,
    step,
    onConfirmEmail,
    onSendPhoneCode,
    onConfirmPhone,
    accessToken,
    onSetStep,
    goBack,
}) => (
    <Box borderRadius={20} 
        backgroundColor="#FFFFFF" 
        w="auto" 
        position="relative"
        p="60px"
        className="fixed_m_box"
        >
        <AuthCardPanel>
            <Flex
                h="100%"
                flexDirection="column"
                justifyContent="space-around"
                position="relative"
                >
                <IconButton
                    aria-label="Back"
                    variant="unstyled"
                    position="absolute"
                    top={{ base: '27px', lg: '-7px' }}
                    left={{ base: '-3px', lg: '-7px' }}
                    d="flex"
                    alignItems="center"
                    onClick={() => goBack?.()}
                >
                    <BackArrow />
                </IconButton>
                <Text
                    fontWeight={900}
                    fontSize={{ base: '18px', lg: '32px' }}
                    color="brand"
                    mt={{ base: '35px', lg: '50px' }}
                    ml={{ base: '50px', md: 0 }}
                >
                    Let&apos;s Create Your Account
                </Text>
                <SignUpForm
                    loading={loading}
                    stateErrors={stateErrors}
                    onSendEmailCode={onSendEmailCode}
                    step={step}
                    nestedPath={nestedPath}
                    onConfirmEmail={onConfirmEmail}
                    onSendPhoneCode={onSendPhoneCode}
                    onConfirmPhone={onConfirmPhone}
                    accessToken={accessToken}
                    onSetStep={onSetStep}
                />
                <div className="g-auth-outer">
                    <GoogleAuth
                        isSign={false}
                    />
                </div>
            </Flex>
        </AuthCardPanel>
    </Box>
);
